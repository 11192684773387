<template>
  <div class="terminal-view">
    <div class="page-title mb-3">
      <h3>Терминалы</h3>
    </div>

    <div class="position-relative">
      <ul class="nav nav-tabs w-100" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" @click="excelButtonsHide">
          <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
             aria-selected="true">Терминалы</a>
        </li>
        <!--        <li class="nav-item" role="presentation" @click="excelButtonsShow">-->
        <!--          <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Ключи терминалов</a>-->
        <!--        </li>-->
      </ul>
      <button class="btn btn-success position-absolute r-0 add-terminal-button" v-if="excel">Скачать в EXCEL</button>
      <button @click="openAddTerminalModal()" class="btn btn-primary position-absolute r-0 add-terminal-button" v-else>
        Добавить терминал
      </button>
    </div>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <div class="table__sh-wrapper w-100">
          <h5>Пользователи</h5>
          <table class="table__sh w-100 border-0">

            <tr class="thead">
              <td>Наименование</td>
              <td>Логин</td>
              <td>Номер телефона</td>
              <td>Роль</td>
              <td>Статус</td>
            </tr>

            <tr v-for="(item, itemIndex) in usersList"
                :key="itemIndex"
                :class="activeUser(item.id) ? 'bg-grey' : ''"
                class="cursor-pointer"
            >
              <td @click="selectUser(item)">{{ item.fio }}</td>
              <td @click="selectUser(item)">{{ item.login }}</td>
              <td @click="selectUser(item)">{{ item.phone }}</td>
              <td @click="selectUser(item)">{{ getNameById(roles, item.role, 'name') }}</td>
              <td @click="selectUser(item)">{{ getNameById(statuses, item.userStatus, 'name') }}</td>
            </tr>

          </table>
          <div class="pagination-wrapper"
               v-if="pagination.totalCount > 1">
            <v-pagination
                v-model="pagination.page"
                :classes="paginationClasses"
                :page-count="pagination.totalCount"></v-pagination>
          </div>
        </div>

        <div v-if="selectedUser" class="table__sh-wrapper w-100 mt-5">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h5>Терминалы {{ selectedUser.fio }}</h5>
            <div class="d-flex">
              <input
                  v-model="searchTerminal"
                  type="text" class="form-control no-focus-input w-300px">
              <button @click="getUserTerminals()" class="btn btn-success ml-3">
                Поиск
              </button>
            </div>
          </div>
          <table
              v-if="userTerminals.length > 0"
              class="table__sh w-100 border-0">

            <tr class="thead">
              <td>Сериа номер терминала</td>
              <td>Пользователь</td>
              <td>Дата добавления</td>
              <td>Статус</td>
              <td></td>
            </tr>

            <tr v-for="(item, itemIndex) in userTerminals" :key="itemIndex">
              <td>{{ item.serialNumber }}</td>
              <td>{{ getNameById(usersList, item.userId, 'fio') }}</td>
              <td>{{ item.addedDate | commonDateFormat }}</td>
              <td>{{ item.isActive ? 'Актив' : 'Неактив' }}</td>
              <td style="width: 150px">
                <div class="w-100 d-flex justify-content-center">
                  <!--                <i class="action-icon">-->
                  <!--                  <img src="../../../public/img/icon-eye.png" alt="">-->
                  <!--                </i>-->
                  <i @click="getTerminal(item)" class="action-icon" v-if="$store.state.roleId !=3">
                    <img src="../../../public/img/icon-pen.png" alt="">
                  </i>
                  <i @click="getTerminal(item)" class="action-icon" >
                    <img src="../../../public/img/icon-eye.png" alt="">
                  </i>
                <div v-if="$store.state.roleId !=3">
                  <i v-if="!item.isActive" @click="activateTerminal(item)" class="action-icon">
                    <img  src="../../../public/img/open-padlock.svg" alt="" class="trash-icon">
                  </i>
                  <i v-else @click="deActivateTerminal(item)" class="action-icon">
                    <img src="../../../public/img/padlock.svg" alt="" class="trash-icon">
                  </i>
                </div>
                </div>
              </td>
            </tr>

          </table>
          <p v-else>Нет терминалы пользователя</p>
          <div class="pagination-wrapper"
               v-if="terminalPagination.totalCount > 1">
            <v-pagination
                v-model="terminalPagination.page"
                :classes="paginationClasses"
                :page-count="terminalPagination.totalCount"></v-pagination>
          </div>
        </div>

      </div>
    </div>
    <!--  Modal  -->
    <b-modal size="lg" v-model="modal"
             :title="update ? 'Изменить' : 'Добавить'"
             hide-footer>
      <div>
        <b-row>
          <b-col>
            <div class="form-group">
              <label>userId</label>
              <b-form-select
                  v-model="$v.terminal.userId.$model"
                  :class="{'is-invalid': $v.terminal.userId.$error}"
                  :options="usersList"
                  value-field="id"
                  text-field="fio"
              ></b-form-select>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>serialNumber</label>
              <b-form-input
                  v-model="$v.terminal.serialNumber.$model"
                  :class="{'is-invalid': $v.terminal.serialNumber.$error}"
                  placeholder="serialNumber"
                  oninput="this.value = this.value.replace(/[^A-Z0-9a-z.]/g, '').replace(/(\..*?)\..*/g, '$1');"

              ></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="form-group">
              <label>appLang</label>
              <b-form-input
                  v-model="terminal.appLang"
                  placeholder="appLang"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>eventInterval</label>
              <b-form-input
                  v-model="terminal.eventInterval"
                  placeholder="eventInterval"></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="form-group">
              <label>uMerchantId</label>
              <b-form-input
                  v-model="terminal.uMerchantId"
                  placeholder="uMerchantId"></b-form-input>
            </div>
          </b-col>

          <b-col>
            <div class="form-group">
              <label>uTerminalId</label>
              <b-form-input
                  v-model="terminal.uTerminalId"
                  placeholder="uTerminalId"></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="form-group">
              <label>hMerchantId</label>
              <b-form-input
                  v-model="terminal.hMerchantId"
                  placeholder="hMerchantId"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>hTerminalId</label>
              <b-form-input
                  v-model="terminal.hTerminalId"
                  placeholder="hTerminalId"></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="form-group">
              <label>locationInterval</label>
              <b-form-input
                  v-model="terminal.locationInterval"
                  placeholder="locationInterval"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>batchUploadInterval</label>
              <b-form-input
                  v-model="terminal.batchUploadInterval"
                  placeholder="batchUploadInterval"></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="form-group">
              <label>locationIntervalSpeed</label>
              <b-form-input
                  v-model="terminal.locationIntervalSpeed"
                  placeholder="locationIntervalSpeed"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>lowBatteryLevel</label>
              <b-form-input
                  v-model="terminal.lowBatteryLevel"
                  placeholder="lowBatteryLevel"></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="form-group">
              <label>minimumBatteryLevel</label>
              <b-form-input
                  v-model="terminal.minimumBatteryLevel"
                  placeholder="minimumBatteryLevel"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>printerDateFormat</label>
              <b-form-input
                  v-model="terminal.printerDateFormat"
                  placeholder="printerDateFormat"></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="form-group">
              <label>recoInterval</label>
              <b-form-input
                  v-model="terminal.recoInterval"
                  placeholder="recoInterval"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>deviceModel</label>
              <b-form-input
                  v-model="terminal.deviceModel"
                  placeholder="deviceModel"></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="form-group">
              <label>ip</label>
              <b-form-input
                  v-model="terminal.ip"
                  placeholder="ip"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>port</label>
              <b-form-input
                  v-model="terminal.port"
                  placeholder="port"></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="form-group">
              <label>printerTextSizeLarge</label>
              <b-form-input
                  v-model="terminal.printerTextSizeLarge"
                  placeholder="printerTextSizeLarge"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>printerTextSize</label>
              <b-form-input
                  v-model="terminal.printerTextSize"
                  placeholder="printerTextSize"></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="form-group"
                style="margin-bottom: 8px">
              <b-form-checkbox
                  v-model="terminal.isChangeable">
                isChangeable
              </b-form-checkbox>
            </div>
            <div>
              <b-form-input
                  disabled
                  v-model="terminal.temporaryPin"
                  ></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>reversalTime</label>
              <b-form-input
                  v-model="terminal.reversalTime"
                  placeholder="reversalTime"></b-form-input>
            </div>
          </b-col>
        </b-row>


        <div class="d-flex justify-content-between align-items-start" v-if="$store.state.roleId !=3">
          <button class="btn btn-success" @click="generateTemporaryPin">Generate Temporary Pin</button>
          <div class="d-flex">
            <button class="btn btn-primary mr-2" @click="submitTerminal">Сохранить</button>
            <button @click="modal = false" class="btn btn-secondary">Отменить</button>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {required, sameAs} from "vuelidate/lib/validators";

export default {
  name: "Terminals",
  data() {
    return {
      excel: false,
      modal: false,
      update: false,
      pagination: {
        page: 1,
        count: 20,
        totalCount: 1
      },
      terminalPagination: {
        page: 1,
        count: 20,
        totalCount: 1
      },
      paginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      usersList: [],
      roles: [],
      statuses: [],
      userTerminals: [],
      selectedUser: null,
      searchTerminal: null,
      terminal: {
        id: 0,
        userId: null,
        serialNumber: '',
        appLang: '',
        eventInterval: 0,
        locationInterval: 0,
        batchUploadInterval: 0,
        locationIntervalSpeed: 0,
        lowBatteryLevel: 0,
        minimumBatteryLevel: 0,
        uTerminalId: '',
        uMerchantId: '',
        hTerminalId: '',
        hMerchantId: '',
        printerDateFormat: '',
        recoInterval: 0,
        deviceModel: '',
        ip: '',
        port: 0,
        printerTextSizeLarge: 0,
        printerTextSize: 0,
        isChangeable: false,
        reversalTime: 0,
        roleId : localStorage.getItem('role')
      },
    }
  },
  methods: {
    filterInput(e){
      let txt = String.fromCharCode(e.which);
      if (!txt.match(/[A-Za-z0-9]/)){
        return false
      }
    },
    excelButtonsShow() {
      this.excel = true
    },
    excelButtonsHide() {
      this.excel = false
    },
    async getUsers() {
      let params = {
        page: this.pagination.page,
        count: this.pagination.count,
      }
      try {
        this.showLoader();
        const res = await this.$store.dispatch('getUsers', params);
        this.usersList = res.result.users;
        this.pagination.totalCount = Math.ceil(res.result.count / this.pagination.count);
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },
    async getRoles() {
      const res = await this.$store.dispatch('getRole');
      this.roles = res.result.roles;
    },
    async getStatuses() {
      const res = await this.$store.dispatch('getStatuses');
      this.statuses = res.result.userStatuses;
    },
    selectUser(user) {
      this.selectedUser = user;
    },
    async getUserTerminals() {
      try {
        this.showLoader();
        const res = await this.$http.get('Admin/GetUserTerminals', {
          params: {
            userId: this.selectedUser.id,
            page: this.terminalPagination.page,
            count: this.terminalPagination.count,
            text: this.searchTerminal
          }
        });
        this.userTerminals = res.result.terminals;
        this.terminalPagination.totalCount = Math.ceil(res.result.count / this.terminalPagination.count);
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },
    async getTerminal(terminal) {
      this.terminalId = terminal.id;
      this.showLoader();
      try {
        const res = await this.$http.get('Admin/GetTerminal', {params: {terminalId: terminal.id}});
        this.update = true;
        this.modal = true;
        this.terminal = res.result;
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },
    async submitTerminal() {
      this.$v.terminal.$touch();
      if (!this.$v.terminal.$invalid) {
        try {
          const res = await this.$http.post('Admin/SubmitTerminal', this.terminal);
          if(res) {
            await this.getUserTerminals(this.selectedUser.id);
            this.modal = false;
            this.successNotify();
          }
        } catch (e) {
          this.errorNotify(e.response.data.error.message);
        }
      }
    },
    async activateTerminal(user) {
      try {
        const res = await this.$http.patch(`Admin/ActivateTerminal?terminalId=${user.id}`);
        if (res) {
          user.isActive = true;
          this.successNotify();
        }
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
    },
    async deActivateTerminal(user) {
      try {
        const res = await this.$http.delete(`Admin/DeactivateTerminal?terminalId=${user.id}`);
        if (res) {
          user.isActive = false;
          this.successNotify();
        }
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
    },
    openAddTerminalModal() {
      this.update = false;
      this.resetTerminalForm();
      this.modal = true;
    },
    resetTerminalForm() {
      this.terminal.id = 0;
      this.terminal.userId = 0;
      this.terminal.serialNumber = '';
      this.terminal.appLang = '';
      this.terminal.eventInterval = 0;
      this.terminal.locationInterval = 0;
      this.terminal.batchUploadInterval = 0;
      this.terminal.locationIntervalSpeed = 0;
      this.terminal.lowBatteryLevel = 0;
      this.terminal.minimumBatteryLevel = 0;
      this.terminal.uTerminalId = '';
      this.terminal.uMerchantId = '';
      this.terminal.hTerminalId = '';
      this.terminal.hMerchantId = '';
      this.terminal.printerDateFormat = '';
      this.terminal.recoInterval = 0;
      this.terminal.deviceModel = '';
      this.terminal.ip = '';
      this.terminal.port = 0;
      this.terminal.printerTextSizeLarge = 0;
      this.terminal.printerTextSize = 0;
      this.terminal.isChangeable = false;
      this.terminal.reversalTime = 0;
    },
    activeUser(id) {
      if (this.selectedUser) {
        return this.selectedUser.id === id
      }
    },
    async generateTemporaryPin() {
      this.showLoader();
      try {
        const res = await this.$http.get('Admin/GenerateTemporaryPin', { params: { terminalId:  this.terminal.id }});
        this.terminal = res.result;
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },

  },
  watch: {
    'pagination.page': function (val) {
      this.getUsers();
    },
    'selectedUser': function () {
      this.getUserTerminals();
    },
    'terminalPagination.page': function () {
      this.getUserTerminals();
    }
  },
  created() {
    this.getUsers();
    this.getRoles();
    this.getStatuses();
  },
  validations: {
    terminal: {
      userId: {required},
      serialNumber: {required}
    }
  },
}
</script>

<style scoped>
  .action-icon {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
